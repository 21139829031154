import * as React from "react"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <p>Sorry page not found</p>
    </main>
  )
}

export default NotFoundPage
